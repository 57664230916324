<template>
    <v-row>
        <ValidationObserver ref="form" v-slot="{ invalid }" class="displayTemplate">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                    <v-text-field
                        outlined
                        autofocus
                        id="primero"
                        label="Nombre"
                        v-model="sucursal.nombre"
                        :error-messages="errors"
                    />
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Código postal" rules="required">
                    <v-text-field
                        outlined
                        label="Código postal"
                        v-model="busca_cp"
                        :error-messages="errors"
                        @keyup.enter="busca_codigos()"
                        v-on:keydown.tab="busca_codigos()"
                        v-mask="'#####'"
                    />
                </ValidationProvider>
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" class="py-0">
                <v-text-field
                    outlined
                    label="Estado"
                    v-model="estado"
                    disabled
                />
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" class="py-0">
                <v-text-field
                    outlined
                    label="Municipio"
                    v-model="municipio"
                    disabled
                />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="8" lg="8" xl="8" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Dirección" rules="required">
                    <v-text-field
                        outlined
                        label="Dirección"
                        v-model="sucursal.direccion"
                        required
                        :error-messages="errors"
                    />
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Colonia" rules="required">
                    <v-autocomplete
                        outlined
                        :items="colonias"
                        item-text="nombre"
                        item-value="id"
                        label="Colonia"
                        persistent-hint
                        v-model="sucursal.colonia_id"
                        :error-messages="errors"
                    />
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                <v-text-field
                    outlined
                    label="Descripción"
                    v-model="sucursal.descripcion"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0 d-flex">
                <ValidationProvider v-slot="{ errors }" name="Distancia mínima para checar" rules="required|numeros_positivos_sin_cero">
                    <v-text-field
                        outlined
                        label="Distancia mínima para checar"
                        v-mask="'#####'"
                        v-model="sucursal.distancia_checada"
                        hint="Distancia en metros"
                        :error-messages="errors"
                    >
                    </v-text-field>
                </ValidationProvider>
                <v-tooltip top class="pb-7">
                    <template v-slot:activator="{ on }">
                        <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2">help_outline</v-icon>
                        </template>
                    <span class="pb-7">La configuración que realices en este apartado se verá reflejado en los puntos donde el 
                        <br> usuario realice chequeo facial desde la aplicación móvil.</span>
                </v-tooltip>
            </v-col>

           <div style="margin-bottom: 15px;" v-show="sucursal.distancia_checada > 0">
            <transition  name="slide" mode="out-in">
                <div class="row px-0 mx-0 pb-4">
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <v-btn class="btnUbicacion d-flex pl-3 pr-9" elevation="0" @click="addMarker()">
                            <v-icon class="tamIcono outlined_v_icon d-flex justify-start" style="margin-inline-end:auto;">add_location_alt</v-icon>
                            Agregar ubicación
                        </v-btn>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="d-flex justify-start align-center">
                        <span style="font-size: 11px; color: grey;">
                            Haz click en el marcador para eliminarlo.
                        </span>
                    </v-col>
                </div>
            </transition>

            <transition v-show="sucursal.distancia_checada > 0" name="slide" mode="out-in">
                <div class="row px-0 mx-3">
                    <GmapMap
                        ref="mymap"
                        :center="coordenadas"
                        :zoom="14"
                        :options="{ streetViewControl: false }"
                        map-type-id="roadmap"
                        style="width: 500px; height: 300px"
                    >
                        <gmap-marker
                            v-for="(coordenada, index) in arrCoordenadas"
                            :key="index"
                            :position="coordenada"
                            :title="'Ubicación #' + (index + 1)"
                            :draggable="true"
                            @click="deleteMarker(index)"
                            @dragend="updateCoordinates($event.latLng, index)"
                        />
                    </GmapMap>
                </div>
          </transition>
        </div>
        </ValidationObserver>
    </v-row>
</template>



<script>
import sucursalApi from "../api/sucursales";
import catalogoApis from "../api/catalogos";
import clientesAPi from "../api/clientes";
import empresaApi from "../api/empresas";
import Swal from "sweetalert2";
import Notify from "@/plugins/notify";
import Datatable from "@/components/datatable/Datatable.vue";
import PerfectScrollbar from "perfect-scrollbar";

export default {
    name: "pop-sucursal",
    props: {
        oyente: Boolean,
        reset:Boolean,
    },
    data() {
        return {
            filters: {
                activo: true,
                paginate: true,
                include_padres: true,
                empresa_id: null,
                cliente_id: null,
            },
            loading_guardar: false,
            dialog: false,
            datosLogin: "",
            sucursal: {
                id: 0,
                nombre: "",
                direccion: "",
                estado_id: null,
                municipio_id: null,
                cp_id: null,
                colonia_id: null,
                descripcion: "",
                empresa_id: null,
                cliente_id: null,
                distancia_checada: null,
                coordenadas: null,
                status:"sucursal",
                formValidate: false
                
            },
            arrCoordenadas: [],
            coordenadas: {
                lat: 19.00174,
                lng: -99.24221,
            },
            empresas: [],
            codigosP: [],
            colonias: [],
            titulo_modal: "",
            finaliza_carga: false,
            parametros_base: { activo: true, paginate: false, order: "asc" },
            accion: "",
            rol: "",
            clientes: [],
            cliente_value: null,
            empresa_value: null,
            estado_value: null,
            municipio_id: null,
            codigo_postal_id: null,
            parametros_final: 0,
            indexMarker: null,
            imagen: "/static/modal/sucursal.png",

            busca_cp: "",
            estado: "",
            municipio: "",
            show: false,
        };
    },
    watch: {
        cliente_value: function(val) {
            this.cambiaCliente(val);
        },
        busca_cp: function(val){
            if(val != null){
                if(val.length == 5){
                    this.busca_codigos();
                }
                else{
                    this.estado = "";
                    this.municipio = "";
                    this.colonias = [];
                }
            }  
        },
        oyente: function(val) {
            if (val) {
                this.recopilaDatos();
            }
        },
        reset: function(val){
            if(val){
                this.resetValues();
                this.init();
                this.$nextTick(() => {
                    this.$refs.form.reset();
                });
            }
        },
    },
    methods: {
        recopilaDatos() {
            this.$refs.form.validate().then(success => {

                if (!success) {
                    this.$emit("resetValorGuardar"); 
                    return;
                }
                //this.guardar();

                this.sucursal.codigo_postal = "0";

                if (this.sucursal.distancia_checada > 0) {
                    if (this.arrCoordenadas.length == 0) {
                        this.loading_guardar = false;
                        Notify.Error(
                            "Nueva sucursal",
                            "Es necesario que coloques el marcador del mapa en la ubicación de la sucursal."
                        );
                        
                        this.$emit("oyenteFalse", false);
                        return;
                    } else {
                        this.sucursal.coordenadas = JSON.stringify(this.arrCoordenadas);
                    }
                }

                this.sucursal.cliente_id = this.cliente_value;
                this.sucursal.empresa_id = this.empresa_value;

                if(this.arrCoordenadas.length > 0){
                    this.sucursal.coordenadas = JSON.stringify(this.arrCoordenadas);
                }
                
                if (success) {
                this.sucursal.formValidate = this.sucursal.formValidate
                    ? false
                    : this.sucursal.formValidate;
                } else {
                    this.sucursal.formValidate = true;
                }
                this.$emit("save", this.sucursal);
            });
        },
        busca_codigos() {
            let self = this;
            let params = {
                activo: true,
                nombre: this.busca_cp,
                include_padres: true,
            };
            catalogoApis.getCP(params).then((response) => {
                let result = response.data;
                if (result.length == 0) {
                    Notify.ErrorToast("No se encontro el Código postal.");
                    this.estado = "";
                    this.municipio = "";
                    this.colonias = [];
                    return;
                }
                this.estado = result[0].nomb_estado;
                this.municipio = result[0].nomb_mun;
                this.sucursal.estado_id = result[0].estado_id;
                this.sucursal.municipio_id = result[0].municipio_id;
                this.sucursal.cp_id = result[0].id;

                catalogoApis.getColonias({ codigo_postal_id: result[0].id, order: "asc", paginate: false }).then((response) => {
                    self.colonias = response.data;
                    if (self.accion == "update") {
                        self.finaliza_carga = true;
                    }
                })
                .catch((e) => {
                    Notify.ErrorToast(
                        "Se presento un problema al cargar las colonias."
                    );
                    return;
                });
            })
            .catch((e) => {
                console.log(e);
                Notify.ErrorToast(
                    "Ocurrió un error al cargar los estados y ciudades."
                );
            });
        },
        async getCP(id) {
            await catalogoApis.getCodigoPostal(id).then((response) => {
                this.sucursal.busca_cp = response.nombre;
                this.busca_cp = response.nombre;
                this.busca_codigos();
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los codigos postales.");
            });
        },
        listar() {
            this.resetValues();
            if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            } else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            }
        },
        cargar(){
            this.resetValues();
            this.errors.clear();
            this.accion = accion;
            this.finaliza_carga = false;

            if (this.rol == "root") {
                this.getClientes();
            }
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.cambiaCliente(
                    this.datosLogin.cliente_id,
                    this.empresa_value
                );
            }
            if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }

            let item_coordenadas = {
                lat: this.coordenadas.lat,
                lng: this.coordenadas.lng,
            };

            this.arrCoordenadas.push(item_coordenadas);
        },
        addMarker() {
            let lat = this.arrCoordenadas[this.arrCoordenadas.length - 1].lat;
            let lng = this.arrCoordenadas[this.arrCoordenadas.length - 1].lng+0.003;
            let nuevaCordenada = { lat, lng }
            this.arrCoordenadas.push( nuevaCordenada );
        },
        deleteMarker(index) {
            if (this.arrCoordenadas.length != 1) {
                this.arrCoordenadas.splice(index, 1);
            }
        },
        updateCoordinates(event, index) {
            let item = {
                lat: parseFloat(event.lat().toFixed(5)),
                lng: parseFloat(event.lng().toFixed(5)),
            };

            this.arrCoordenadas.splice(index, 1, item);
        },

        getClientes() {
            let parametros = { activo: true, paginate: false };
            clientesAPi.getClientes(parametros).then((response) => {
                this.clientes = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },
        cambiaCliente(id, empresa = null) {
            if (this.sucursal.cliente_id != id) {
                this.empresa_value = null;
                this.sucursal.empresa_id = null;
            }
            let parametros = { activo: true, paginate: false, cliente_id: id };
            empresaApi.getEmpresas(parametros).then((response) => {
                this.empresas = response.data;
                if (this.sucursal.empresa_id != null) {
                    this.empresa_value = this.sucursal.empresa_id;
                }
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                if (success) {
                this.loading_guardar = true;

                this.sucursal.cliente_id = this.cliente_value;
                this.sucursal.empresa_id = this.empresa_value;

                this.sucursal.codigo_postal = "0";
                if (this.sucursal.distancia_checada > 0) {
                    if (this.arrCoordenadas.length == 0) {
                        this.loading_guardar = false;
                        Notify.Error(
                            "Nueva sucursal",
                            "Es necesario que coloques el marcador del mapa en la ubicación de la sucursal."
                        );
                        return;
                    } else {
                        this.sucursal.coordenadas = JSON.stringify(this.arrCoordenadas);
                    }
                }
                }
            });
        },
        cerrarModal() {
            this.resetValues();
        },
        resetValues() {
            (this.sucursal = {
                id: 0,
                nombre: "",
                direccion: "",
                estado_id: null,
                municipio_id: null,
                cp_id: null,
                colonia_id: null,
                descripcion: "",
                empresa_id: null,
                cliente_id: null,
                distancia_checada: null,
                coordenadas: null,
                status:"sucursal",
                formValidate: false
            }),
                (this.estado_value = null);
            this.cliente_value = null;
            this.empresa_value = null;
            this.municipio_id = null;
            this.codigo_postal_id = null;
            this.parametros_final = 0;
            this.loading_guardar = false;
            this.clientes = [];
            this.empresas = [];
            this.arrCoordenadas = [];
            this.busca_cp = null;
            this.colonias = [];
            this.estado = "";
            this.municipio = "";
        },
        respuesta_error(err) {
            this.loading_guardar = false;
            let error = Object.values(err.response.data.error);
            let leyenda = ``;
            for (var i = 0; i < error.length; i++) {
                leyenda += `* ` + error[i] + `\n`;
            }

            Notify.ErrorToast(leyenda);
        },
        respuesta_ok(msj) {
            this.listar();
            this.cerrarModal();
            Notify.Success("Operación exitosa", msj);
        },
        getGeoPosition(position) {
            this.coordenadas.lat = position.coords.latitude;
            this.coordenadas.lng = position.coords.longitude;

            this.arrCoordenadas.splice(0, 1, {
                lat: this.coordenadas.lat,
                lng: this.coordenadas.lng,
            });
        },
        init(){
            if (navigator.geolocation) {
                var options = {
                enableHighAccuracy: true,
                timeout: 10 * 1000 * 1000,
                maximumAge: 0,
                };

                navigator.geolocation.getCurrentPosition(
                this.getGeoPosition,
                () => console.error(err),
                options
                );
            } else {
                alert("No se concedieron permisos de geolocalización para la sucursal.");
            }

            if (this.rol == "root") {
                    this.getClientes();
            }
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.cambiaCliente(this.datosLogin.cliente_id,this.empresa_value);
            }
            if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }

            this.listar();
        }
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        if (navigator.geolocation) {
        var options = {
            enableHighAccuracy: true,
            timeout: 10 * 1000 * 1000,
            maximumAge: 0,
        };

        navigator.geolocation.getCurrentPosition(
            this.getGeoPosition,
            () => console.error(err),
            options
        );
        } else {
        alert("No se concedieron permisos de geolocalización para la sucursal.");
        }

        this.listar();
    },
};
</script>

<style scoped>
.btnUbicacion {
    width: 232px !important;
    height: 48px;
    border-radius: 30px;
    text-transform: none;
    background:#E0E0E0 !important;
    color:#828282 !important;
}
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background: #004baf;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}
.displayTemplate{
    display: contents;
}
</style>