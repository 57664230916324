<template>
    <v-row>
        <ValidationObserver ref="form" v-slot="{ invalid }" class="displayTemplate">       
            <v-col cols="12" xs="12" sm="8" md="8" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                    <v-text-field 
                        outlined
                        label="Nombre de la empresa" 
                        class="pa-0 ma-0" 
                        v-model="empresa.nombre" 
                        :error-messages="errors" 
                        required
                        id="txtNombre"
                    >
                    </v-text-field>
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="4" md="4" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Clave" rules="required">
                    <v-text-field 
                        outlined
                        label="Clave" 
                        class="pa-0 ma-0" 
                        v-model="empresa.clave"
                        :error-messages="errors" 
                        required
                        id="txtClave"
                    >
                    </v-text-field>
                </ValidationProvider>
            </v-col>
            <!-- <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Nombre Comercial" rules="required">
                    <v-text-field
                        outlined
                        label="Nombre Comercial" 
                        class="pa-0 ma-0" 
                        v-model="empresa.nombre_comercial" 
                        :error-messages="errors" 
                        required
                        id="txtComercial"
                    >
                    </v-text-field>
                </ValidationProvider>
            </v-col> -->
            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Razón Social" rules="required">
                    <v-text-field 
                        outlined
                        label="Razón Social" 
                        class="pa-0 ma-0" 
                        v-model="empresa.razon_social" 
                        :error-messages="errors" 
                        required
                        id="txtRS"
                        >
                    </v-text-field>
                </ValidationProvider>
            </v-col>
            <!-- <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Descripción del Giro" rules="required">
                    <v-text-field 
                        outlined
                        label="Descripción del Giro" 
                        class="pa-0 ma-0" 
                        v-model="empresa.giro_descripcion" 
                        :error-messages="errors" 
                        required
                        id="txtDescripcion">
                    </v-text-field>
                </ValidationProvider>
            </v-col> -->
            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="RFC" rules="required|rfc">
                    <v-text-field 
                        outlined 
                        label="RFC" 
                        class="pa-0 ma-0" 
                        v-model="empresa.rfc" 
                        :error-messages="errors" 
                        required
                        id="txtRFC"
                    >    
                    </v-text-field>
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="URL de Servidor" rules="https_puerto">
                    <v-text-field 
                        outlined
                        label="URL de servidor" 
                        class="pa-0 ma-0" 
                        v-model="empresa.url_server_asp" 
                        :error-messages="errors" 
                        required
                        id="txtURL"
                    >
                    </v-text-field>
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Dirección" rules="required">
                    <v-text-field 
                        outlined
                        label="Dirección" 
                        class="pa-0 ma-0" 
                        v-model="empresa.direccion" 
                        :error-messages="errors" 
                        required
                        id="txtDireccion"
                    >
                    </v-text-field>
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="CP" rules="required">
                    <v-text-field
                        outlined
                        label="Código postal"
                        persistent-hint
                        v-model="busca_cp"
                        class="pa-0 ma-0"
                        required 
                        :error-messages="errors" 
                        @keyup.enter="busca_codigos()"
                        v-on:keydown.tab="busca_codigos()"
                        v-mask="'#####'"
                        id="txtCP"
                    >
                    </v-text-field>
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                <v-text-field
                    outlined
                    label="Estado"
                    v-model="estado"
                    disabled
                    class="pa-0 ma-0"
                    id="txtEstado"
                >
                </v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                <v-text-field
                    outlined
                    label="Municipio"
                    v-model="municipio"
                    disabled
                    class="pa-0 ma-0"
                    id="txtMunicipio"
                >
                </v-text-field>
            </v-col>
            
            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Colonia" rules="required">
                    <v-autocomplete
                        outlined
                        label="Colonia"
                        :items="colonias"
                        item-text="nombre"
                        item-value="id"
                        persistent-hint
                        v-model="empresa.colonia_id"
                        class="pa-0 ma-0"
                        required 
                        :error-messages="errors" 
                        id="txtColonia"
                    >
                    </v-autocomplete>
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Telefono" rules="required">
                    <v-text-field 
                        outlined
                        label="Teléfono" 
                        class="pa-0 ma-0" 
                        v-mask="'(###) ###-####'"
                        v-model="empresa.telefono_contacto" 
                        :error-messages="errors" 
                        required
                        id="txtTelefono"
                    >    
                    </v-text-field>
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Contacto" rules="required">
                    <v-text-field 
                        outlined
                        label="Contacto" 
                        class="pa-0 ma-0" 
                        v-model="empresa.nombre_contacto" 
                        :error-messages="errors" 
                        required
                        id="txtContacto"
                    > 
                    </v-text-field>
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Correo Contacto" rules="required|email" >
                    <v-text-field 
                        outlined
                        label="Correo Contacto" 
                        class="pa-0 ma-0" 
                        v-model="empresa.correo_contacto" 
                        :error-messages="errors" 
                        required
                        id="txtCorreoContacto"
                    >    
                    </v-text-field>
                </ValidationProvider>
            </v-col>
        </ValidationObserver>
    </v-row>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import FormWizard from '@/components/form-wizard/FormWizard.vue';
import Tab from '@/components/form-wizard/Tab.vue';
import apiClientes from "@/api/clientes";
import apiEmpresas from "@/api/empresas";
import catalogoApis from '@/api/catalogos';
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiBancos from "@/api/nominas/bancos";
import apiMetodoPago from "@/api/nominas/metodoPago";
import apiTipoNomina from "@/api/nominas/tipoNominaSAT";
import Notify from '@/plugins/notify';
import queries from '@/queries/Empresas';
import empresas from '@/api/empresas';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';

export default {
    components: {
        VuetifyDatePicker,
        FormWizard, Tab
    },
    name: "pop-empresa",
    props: {
        oyente: Boolean,
        reset:Boolean,
    },
    data() {
        return {
            empresa: {
                id                      : null,
                nombre                  : '',
                razon_social            : '',
                rfc                     : '',
                direccion               : '',
                url_server_asp          : '',
                cliente_id              : null,
                estado_id               : null,
                municipio_id            : null,
                cp_id                   : null,
                colonia_id              : null,
                tipo                    : 'nominas', //gdth o nominas
                clave                   : '',
                registro_patronal_id    : null,
                nombre_comercial        : '',
                giro_cif                : '',
                giro_descripcion        : '',
                banco_empresa_f_id      : null,
                concepto_f              : '',
                concepto_f1             : '',
                metodo_de_pago_id       : null,
                terminacion_cta_cliente : '',
                fecha_contrato          : '',
                objeto_contractural     : '',
                representante_legal     : '',
                divisa                  : '',
                inicio_operaciones      : '',
                tipo_nomina_id          : null,
                telefono_contacto       : '',
                envio_documentacion     : '',
                observaciones_generales : '',
                nombre_contacto         : '',
                correo_contacto         : '',
                solo_pagos              : false,
                formValidate:false,
                status:'empresa'
            },
            datosLogin          : null,
            rol                 : null,
            calendar_contrato   : false,
            calendar_operaciones: false,
            clientes            : [],
            estado_id           : null,
            municipio_id        : null,
            cp_id               : null,
            estados             : [],
            municipios          : [],
            ciudades            : [],
            codigosPostales     : [],
            colonias            : [],
            registroPatronal    : [],
            bancos              : [],
            metodosPagos        : [],
            tiposNominas        : [],
            busca_cp            : "",
            municipio           : "",
            estado              : "", 
        }
    },
    watch:{
        busca_cp: function(val){
            if(val.length == 5){
                this.busca_codigos();
            }
            else{
                this.estado = "";
                this.municipio = "";
                this.colonias = [];
            }
        },
        oyente: function(val) {
            if (val) {
                this.recopilaDatos();
            }
        },
        reset: function(val){
            if(val){
                this.resetValues();
                this.init();
                this.$nextTick(() => {
                    this.$refs.form.reset();
                });
            }
        },
    },
    methods: {
         init(){

            if (this.rol == "root") {
                    this.getClientes();
            }else{
                this.cliente_value = this.datosLogin.cliente_id;
            }
        },
        recopilaDatos() {
            
            this.$refs.form.validate().then(success => {
                if (!success) {
                    this.$emit("resetValorGuardar"); 
                    return;
                }
                if (success) {
                this.empresa.formValidate = this.empresa.formValidate
                    ? false
                    : this.empresa.formValidate;
                } else {
                    this.empresa.formValidate = true;
                }
                this.$emit("save", this.empresa);
            });
        },
        store(valid) {
            if(valid) {
                this.empresa.rfc=this.empresa.rfc.toUpperCase();

                let formData = new FormData();
                if(this.rol != 'root'){
                    this.empresa.cliente_id = this.datosLogin.cliente_id;
                }

                formData.append('nombre', this.empresa.nombre.toString());
                formData.append('razon_social', this.empresa.razon_social.toString());
                formData.append('rfc',this.empresa.rfc.toString());
                formData.append('direccion', this.empresa.direccion.toString());
                formData.append('cliente_id',this.empresa.cliente_id);
                formData.append('estado_id',this.empresa.estado_id);
                formData.append('municipio_id', this.empresa.municipio_id);
                formData.append('cp_id',this.empresa.cp_id);
                formData.append('colonia_id', this.empresa.colonia_id);
                formData.append('tipo', 'nominas');
                formData.append('clave', this.empresa.clave.toString());
                formData.append('nombre_comercial', this.empresa.nombre_comercial.toString());
                formData.append('giro_descripcion', this.empresa.giro_descripcion.toString());
                formData.append('banco_empresa_f_id', this.empresa.banco_empresa_f_id);
                formData.append('metodo_de_pago_id', this.empresa.metodo_de_pago_id);
                formData.append('terminacion_cta_cliente', this.empresa.terminacion_cta_cliente.toString());
                formData.append('representante_legal', this.empresa.representante_legal.toString());
                formData.append('divisa', this.empresa.divisa.toString());
                formData.append('telefono_contacto', this.empresa.telefono_contacto.toString());
                formData.append('nombre_contacto', this.empresa.nombre_contacto.toString());
                formData.append('correo_contacto', this.empresa.correo_contacto.toString());
                formData.append('solo_pagos', this.empresa.solo_pagos.toString());

                if(this.empresa.url_server_asp != null){
                    formData.append('url_server_asp',this.empresa.url_server_asp.toString());
                }
                if(this.empresa.concepto_f != null){
                    formData.append('concepto_f', this.empresa.concepto_f.toString());
                }
                if(this.empresa.concepto_f1 != null){
                    formData.append('concepto_f1', this.empresa.concepto_f1.toString());
                }
                if(this.empresa.objeto_contractural != null){
                    formData.append('objeto_contractural', this.empresa.objeto_contractural.toString());
                }
                if(this.empresa.envio_documentacion != null){
                    formData.append('envio_documentacion', this.empresa.envio_documentacion.toString());
                }
                if(this.empresa.observaciones_generales != null){
                    formData.append('observaciones_generales', this.empresa.observaciones_generales.toString());
                }
                if(this.empresa.fecha_contrato != null){
                    formData.append('fecha_contrato', this.empresa.fecha_contrato.toString());
                }
                if(this.empresa.inicio_operaciones != null){
                    formData.append('inicio_operaciones', this.empresa.inicio_operaciones.toString());
                }

                if(this.action != "update") {
                    apiEmpresas.addEmpresa(formData)
                    .then((response) => {
                        Notify.Success("Excelente!", "La empresa se guardó satisfactoriamente.");
                        this.$modal.hide('empresaModal');
                    })
                    .catch(err => {
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;
    
                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
    
                        Notify.ErrorToast(leyenda);
                    });
                } else {
                    formData.append('id',this.empresa.id);
                    apiEmpresas.updateEmpresaP(formData)
                    .then((response) => {
                        Notify.Success("Excelente!", "La empresa se actualizó satisfactoriamente.");
                        this.$modal.hide('empresaModal');
                    })
                    .catch(err => {
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;
    
                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
    
                        Notify.ErrorToast(leyenda);
                    });
                }
            } else {
                Notify.ErrorToast("Alguno de los campos no son correctos.");
            }
        },
        resetValues() {
            (this.empresa = {
                id                      : null,
                nombre                  : '',
                razon_social            : '',
                rfc                     : '',
                direccion               : '',
                url_server_asp          : '',
                cliente_id              : null,
                estado_id               : null,
                municipio_id            : null,
                cp_id                   : null,
                colonia_id              : null,
                tipo                    : 'nominas', //gdth o nominas
                clave                   : '',
                registro_patronal_id    : null,
                nombre_comercial        : '',
                giro_cif                : '',
                giro_descripcion        : '',
                banco_empresa_f_id      : null,
                concepto_f              : '',
                concepto_f1             : '',
                metodo_de_pago_id       : null,
                terminacion_cta_cliente : '',
                fecha_contrato          : '',
                objeto_contractural     : '',
                representante_legal     : '',
                divisa                  : '',
                inicio_operaciones      : '',
                tipo_nomina_id          : null,
                telefono_contacto       : '',
                envio_documentacion     : '',
                observaciones_generales : '',
                nombre_contacto         : '',
                correo_contacto         : '',
                solo_pagos              : false,
                formValidate:false,
                status:'empresa'
            }),
                
            this.cliente_value = null;
            this.municipio_id = null;
            this.codigo_postal_id = null;
            this.loading_guardar = false;
            this.clientes = [];
            this.busca_cp = "";
            this.colonias = [];
            this.estado = "";
            this.municipio = "";
        },
        storeDraft() {
            this.empresa.rfc=this.empresa.rfc.toUpperCase();
            this.empresa.estado_id = this.estado_id;
            this.empresa.municipio_id = this.municipio_id;
            this.empresa.cp_id = this.cp_id;
            
            this.$session.remove("empresaBorrador");
            this.$session.set('empresaBorrador',this.empresa);
            Notify.Success("Excelente!", "La empresa se guardó el formulario en sesión");
            this.$modal.hide('empresaModal');
            
        },
        cancel() {
            this.$modal.hide('empresaModal');
            this.$session.remove("empresaBorrador");
        },
        getClientes(){
            let parametros = { activo:true, paginate:false, order:"asc"}
            apiClientes.getClientes(parametros).then((response) => {
                this.clientes = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Clientes.");
            });
        },
        getRegistroPatronal(){
            let parametros = { activo:true, paginate:false, order:"asc" }
            apiRegistroPatronal.find(parametros).then((response) => {
                this.registroPatronal = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Registros Patronales.");
            });
        },
        getBancos(){
            let parametros = { activo:true, paginate:false, order:"asc" }
            apiBancos.find(parametros).then((response) => {
                this.bancos = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Bancos.");
            });
        },
        getMetodosPagos(){
            let parametros = { activo:true, paginate:false, order:"asc" }
            apiMetodoPago.find(parametros).then((response) => {
                this.metodosPagos = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Métodos de Pagos.");
            });
        },
        getTiposNominas(){
            let parametros = { activo:true, paginate:false, order:"asc" }
            apiTipoNomina.find(parametros).then((response) => {
                this.tiposNominas = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Tipos de Nominas.");
            });
        },
        async getCP(id) {
            await catalogoApis.getCodigoPostal(id).then((response) => {
                this.busca_cp = response.nombre;
                this.busca_codigos();
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los codigos postales.");
            });
        },
        busca_codigos() {
            let self = this;
            let params = {
                activo: true,
                nombre: this.busca_cp,
                include_padres: true,
            };
            catalogoApis.getCP(params).then((response) => {
                let result = response.data;

                if (result.length == 0) {
                    Notify.ErrorToast("No se encontro el Código postal.");
                    this.estado     = "";
                    this.municipio  = "";
                    this.colonias   = [];
                    return;
                }
                this.estado                 = result[0].nomb_estado;
                this.municipio              = result[0].nomb_mun;
                this.empresa.estado_id      = result[0].estado_id;
                this.empresa.municipio_id   = result[0].municipio_id;
                this.empresa.cp_id          = result[0].id;

                catalogoApis.getColonias({ codigo_postal_id: result[0].id, order: "asc", paginate:false }).then((response) => {
                    self.colonias = response.data;
                })
                .catch((e) => {
                    Notify.ErrorToast(
                        "Se presento un problema al cargar las colonias."
                    );
                return;
                });
            })
            .catch((e) => {
                console.log(e);
                Notify.ErrorToast(
                    "Ocurrió un error al cargar los estados y ciudades."
                );
            });
        },
    },
    async created(){
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        await this.getClientes();
        //await this.getRegistroPatronal();
        //await this.getBancos();
        //await this.getMetodosPagos();
        //await this.getTiposNominas();
       
    },
    mounted() {
    },
}
</script>

<style scoped>
    .header {
        text-align: center !important;
        padding-top: 30px;
        margin-bottom: -30px;
    }
    .title {
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 29px !important;
        line-height: 39px !important;
        color: #101C5A;
    }
    #txtNombre {
        color: #004BAF !important; font-family: Montserrat !important;  font-size: 20px !important
    }
    .scrollbar-template {
        position: relative;
    }
    .displayTemplate{
    display: contents;
}
    </style>