import Vue from 'vue';

export default{
    getDocumentos(parametros){
        return Vue.axios.get("catalogo/tipos-documentos/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getDocumento(id){
        return Vue.axios.get("catalogo/tipos-documentos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateDocumento(parametros){
        return Vue.axios.put("catalogo/tipos-documentos", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteDocumento(id){
        return Vue.axios.delete("catalogo/tipos-documentos/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addDocumento(parametros){
        return Vue.axios.post("catalogo/tipos-documentos", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}