import axios from 'axios';
import env from '../plugins/enviroment';

export default {
    getFaceList(faceId) {
        return axios.get(env.azureAPI + `face/v1.0/largefacelists/${faceId}`, {headers : {'Content-Type' : 'application/json', 'Ocp-Apim-Subscription-Key' : env.azureKey}}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    
    createFaceList(faceId, parametros) {
        return axios.put(env.azureAPI + `face/v1.0/largefacelists/${faceId}`, parametros, {headers : {'Content-Type' : 'application/json', 'Ocp-Apim-Subscription-Key' : env.azureKey}}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    addFace(faceId, parametros, employeeId) {
        return axios.post(env.azureAPI + `face/v1.0/largefacelists/${faceId}/persistedFaces?userData=${employeeId}`, parametros, {headers : {'Content-Type' : 'application/json', 'Ocp-Apim-Subscription-Key' : env.azureKey}}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    getFaces(faceId) {
        return axios.get(env.azureAPI + `face/v1.0/largefacelists/${faceId}/persistedFaces`, { headers: { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': env.azureKey } }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    train(faceId) {
        return axios.post(env.azureAPI + `face/v1.0/largefacelists/${faceId}/train`, {}, { headers: { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': env.azureKey } }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    detect(parametros) {
        return axios.post(env.azureAPI + `face/v1.0/detect`, parametros, {headers : {'Content-Type' : 'application/json', 'Ocp-Apim-Subscription-Key' : env.azureKey}}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    findSimilars(parametros) {
        return axios.post(env.azureAPI + `face/v1.0/findsimilars`, parametros, {headers : {'Content-Type' : 'application/json', 'Ocp-Apim-Subscription-Key' : env.azureKey}}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    deleteFace(faceId, persistedFaceId) {
        return axios.delete(env.azureAPI + `face/v1.0/largefacelists/${faceId}/persistedFaces/${persistedFaceId}`, {headers : {'Content-Type' : 'application/json', 'Ocp-Apim-Subscription-Key' : env.azureKey}}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },


    getLargeFaceLists() {
        return axios.get(env.azureAPI + `face/v1.0/largefacelists/`, {headers : {'Content-Type' : 'application/json', 'Ocp-Apim-Subscription-Key' : env.azureKey}}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    createLargeFaceLists(largeFaceListsId, parametros) {
        return axios.put(env.azureAPI + `face/v1.0/largefacelists/${largeFaceListsId}`, parametros, {headers : {'Content-Type' : 'application/json', 'Ocp-Apim-Subscription-Key' : env.azureKey}}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteLargeFaceList(largeFaceListsId) {
        return axios.delete(env.azureAPI + `face/v1.0/largefacelists/${largeFaceListsId}`, {headers : {'Content-Type' : 'application/json', 'Ocp-Apim-Subscription-Key' : env.azureKey}}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

}