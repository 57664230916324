<template> 
    <v-container>       
        <div class="row">
            <div v-show="cargaModal" id="loading" class="col-lg-12">
                <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular>
            </div>
            <div v-show="!cargaModal" class="col-lg-12">
                <p class="col-4 py-0 px-0 subtitleBig">Datos generales</p>
                <div class="row col-lg-12">
                    <p class="col-4 py-0 px-0 subtitle">Nacionalidad:</p>
                    <p class="col-8 py-0 px-0 text"><span :class="empleado.nacionalidad != null ? '':'text-mute'">{{empleado.nacionalidad != null ? empleado.nacionalidad:"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle">Fecha de nacimiento:</p>
                    <p class="col-8 py-0 px-0 text"><span :class="empleado.fecha_nacimiento != null ? '':'text-mute'">{{empleado.fecha_nacimiento != null ? dateFormat(empleado.fecha_nacimiento):"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle">Lugar de nacimiento:</p>
                    <p class="col-8 py-0 px-0 text"><span :class="empleado.lugar_nacimiento != null ? '':'text-mute'">{{empleado.lugar_nacimiento != null ? empleado.lugar_nacimiento:"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle">Sexo:</p>
                    <p class="col-8 py-0 px-0 text"><span :class="empleado.sexo != null ? '':'text-mute'">{{empleado.sexo != null ? empleado.sexo != "M"? "Femenino":"Masculino":"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle">Estado civil:</p>
                    <p class="col-8 py-0 px-0 text"><span :class="empleado.estadoCivil != null ? '':'text-mute'">{{empleado.estadoCivil != null ? empleado.estadoCivil.nombre:"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle">Código postal:</p>
                    <p class="col-8 py-0 px-0 text"><span :class="empleado.codigoPostal != null ? '':'text-mute'">{{empleado.codigoPostal != null ? empleado.codigoPostal.nombre:"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle">Estado:</p>
                    <p class="col-8 py-0 px-0 text"><span :class="empleado.estado != null ? '':'text-mute'">{{empleado.estado != null ? empleado.estado.nombre:"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle">Municipio:</p>
                    <p class="col-8 py-0 px-0 text"><span :class="empleado.municipio != null ? '':'text-mute'">{{empleado.municipio != null ? empleado.municipio.nombre:"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle">Dirección:</p>
                    <p class="col-8 py-0 px-0 text"><span :class="empleado.direccion != null ? '':'text-mute'">{{empleado.direccion != null ? empleado.direccion:"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle">Colonia:</p>
                    <p class="col-8 py-0 px-0 text"><span :class="empleado.colonia != null ? '':'text-mute'">{{empleado.colonia != null ? empleado.colonia.nombre:"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle">Teléfono:</p>
                    <p class="col-8 py-0 px-0 text"><span :class="empleado.telefono != null ? '':'text-mute'">{{empleado.telefono != null ? empleado.telefono:"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle">Celular:</p>
                    <p class="col-8 py-0 px-0 text"><span :class="empleado.celular != null ? '':'text-mute'">{{empleado.celular != null ? empleado.celular:"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle">E-mail:</p>
                    <p class="col-8 py-0 px-0 text"><span :class="(empleado.correo != null && empleado.correo != '') ? '':'text-mute'">{{(empleado.correo != null && empleado.correo != '') ? empleado.correo:"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle" v-if="tipoDeRegimen">Escolaridad:</p>
                    <p class="col-8 py-0 px-0 text" v-if="tipoDeRegimen"><span :class="empleado.estudios != null ? '':'text-mute'">{{empleado.estudios != null ? empleado.estudios.nombre:"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle" v-if="tipoDeRegimen">Conocimientos extra:</p>
                    <p class="col-8 py-0 px-0 text" v-if="tipoDeRegimen"><span :class="empleado.conocimientos_extras != null ? '':'text-mute'">{{empleado.conocimientos_extras != null ? empleado.conocimientos_extras:"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle">Tipo de contrato:</p>
                    <p class="col-8 py-0 px-0 text"><span :class="empleado.contratoFormato != null ? '':'text-mute'">{{empleado.contratoFormato != null ? empleado.contratoFormato.nombre:"Información faltante*"}}</span></p>
                    <p class="col-4 py-0 px-0 subtitle">Domicilio del servicio:</p>
                    <p class="col-8 py-0 px-0 text"><span :class="empleado.direccion_laboral != null ? '':'text-mute'">{{empleado.direccion_laboral != null ? empleado.direccion_laboral:"Información faltante*"}}</span></p>
                </div>
                <v-divider class="mt-5  divider-gray skiny"></v-divider>
                <p class="col-4 py-0 px-0 mt-7 subtitleBig">Cuentas bancarias</p>
                <div class="mt-4">
                    <div class="row col-lg-12">
                        <template v-if="empleado.cuentasBancarias.length == 0">
                            <p class="col-4 py-0 px-0 subtitle">Banco:</p>
                            <p class="col-8 py-0 px-0 text-mute">Información faltante*</p>
                            <p class="col-4 py-0 px-0 subtitle">Cuenta bancaria:</p>
                            <p class="col-8 py-0 px-0 text-mute">Información faltante*</p>
                            <p class="col-4 py-0 px-0 subtitle">CLABE interbancaria:</p>
                            <p class="col-8 py-0 px-0 text-mute">Información faltante*</p>
                        </template>
                        <template v-else v-for="(banco,index) in empleado.cuentasBancarias">
                            <p v-if="empleado.cuentasBancarias.length == 1" class="col-4 py-0 px-0 subtitle" :key="index">Banco:</p>
                            <p v-else class="col-4 py-0 px-0 subtitle" :key="'A'+index">Banco {{index+1}}:</p>
                            <p class="col-8 py-0 px-0 text" :key="'B'+index"><span :class="banco.banco != null ? '':'text-mute'">{{banco.banco != null ? banco.banco.nombre:"Información faltante*"}}</span></p>
                            <p class="col-4 py-0 px-0 subtitle" :key="'C'+index">Cuenta bancaria:</p>
                            <p class="col-8 py-0 px-0 text" :key="'D'+index"><span :class="banco != null ? '':'text-mute'">{{banco != null ? banco.cuenta_bancaria:"Información faltante*"}}</span></p>
                            <p class="col-4 py-0 px-0 subtitle" :key="'E'+index">CLABE interbancaria:</p>
                            <p class="col-8 py-0 px-0 text" :key="'F'+index"><span :class="banco != null ? '':'text-mute'">{{banco != null ? banco.clabe_bancaria:"Información faltante*"}}</span></p>
                        </template>
                    </div>
                </div>
                 <v-divider class="mt-5  divider-gray skiny"></v-divider>
                <p class="col-4 py-0 px-0 mt-7 subtitleBig">Seguridad social</p>
                <div class="mt-4">
                    <div class="row col-lg-12">
                        <p class="col-4 py-0 px-0 subtitle" v-if="tipoDeRegimen">NSS:</p>
                        <p class="col-8 py-0 px-0 text" v-if="tipoDeRegimen"><span :class="empleado.imss != null ? '':'text-mute'">{{empleado.imss != null ? empleado.imss:'Información faltante*'}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle">RFC:</p>
                        <p class="col-8 py-0 px-0 text"><span :class="empleado.rfc != null ? '':'text-mute'">{{empleado.rfc != null ? empleado.rfc:"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle">CURP:</p>
                        <p class="col-8 py-0 px-0 text"><span :class="empleado.curp != null ? '':'text-mute'">{{empleado.curp != null ? empleado.curp:"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle" v-if="tipoDeRegimen">Tipo de sangre:</p>
                        <p class="col-8 py-0 px-0 text" v-if="tipoDeRegimen"><span :class="empleado.tipo_sangre != null ? '':'text-mute'">{{empleado.tipo_sangre != null ? empleado.tipo_sangre:"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle" v-if="tipoDeRegimen">En caso de emergencia:</p>
                        <p class="col-8 py-0 px-0 text" v-if="tipoDeRegimen">{{empleado.nombre_contacto != null ? empleado.nombre_contacto:"Emergencias"}}</p>
                        <p class="col-4 py-0 px-0 subtitle" v-if="tipoDeRegimen">Teléfono de contacto:</p>
                        <p class="col-8 py-0 px-0 text" v-if="tipoDeRegimen">{{empleado.telefono_contacto != null ? empleado.telefono_contacto:"(911) ó emergencias"}}</p>
                        <p class="col-4 py-0 px-0 subtitle" v-if="tipoDeRegimen">Observaciones de salud:</p>
                        <p class="col-8 py-0 px-0 text" v-if="tipoDeRegimen">{{empleado.observacion_salud != null ? empleado.observacion_salud:"Ninguna"}}</p>
                    </div>
                </div>
                <v-divider class="mt-5  divider-gray skiny"></v-divider>
                <p class="col-4 py-0 px-0 mt-7 subtitleBig">Datos de la empresa</p>
                <div class="mt-4">
                    <div class="row col-lg-12">
                        <p class="col-4 py-0 px-0 subtitle">Cliente:</p>
                        <p class="col-8 py-0 px-0 text"><span :class="empleado.cliente != null ? '':'text-mute'">{{empleado.cliente != null ? empleado.cliente.nombre:"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle">Empresa:</p>
                        <p class="col-8 py-0 px-0 text"><span :class="empleado.empresa != null ? '':'text-mute'">{{empleado.empresa != null ? empleado.empresa.nombre:"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle">Sucursal:</p>
                        <p class="col-8 py-0 px-0 text"><span :class="empleado.sucursal != null ? '':'text-mute'">{{empleado.sucursal != null ? empleado.sucursal.nombre:"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle" v-if="tipoDeRegimen">Departamento:</p>
                        <p class="col-8 py-0 px-0 text" v-if="tipoDeRegimen"><span :class="empleado.departamento != null ? '':'text-mute'">{{empleado.departamento != null ? empleado.departamento.nombre:"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle" v-if="tipoDeRegimen">Horario:</p>
                        <p class="col-8 py-0 px-0 text" v-if="tipoDeRegimen"><span :class="empleado.horario != null ? '':'text-mute'">{{empleado.horario != null ? empleado.horario.nombre:"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle" v-if="tipoDeRegimen">Dias de vacaciones:</p>
                        <p class="col-8 py-0 px-0 text" v-if="tipoDeRegimen"><span :class="empleado.dias_vacaciones != 0 ? '':'text-mute'">{{empleado.dias_vacaciones != 0 ? empleado.dias_vacaciones:"Sin vacaciones*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle" v-if="tipoDeRegimen">Puesto:</p>
                        <p class="col-8 py-0 px-0 text" v-if="tipoDeRegimen"><span :class="empleado.puesto != null ? '':'text-mute'">{{empleado.puesto != null ? empleado.puesto.nombre:"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle">Entidad CFDI:</p>
                        <p class="col-8 py-0 px-0 text"><span :class="empleado.entidad_cfdi != null ? '':'text-mute'">{{empleado.entidad_cfdi != null ? empleado.entidad_cfdi:"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle">Registro Patronal:</p>
                        <p class="col-8 py-0 px-0 text"><span :class="empleado.registroPatronal != null ? '':'text-mute'">{{empleado.registroPatronal != null ? empleado.registroPatronal.nombre:"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle">Fecha de ingreso:</p>
                        <p class="col-8 py-0 px-0 text"><span :class="empleado.fecha_ingreso != null ? '':'text-mute'">{{empleado.fecha_ingreso != null ? dateFormat(empleado.fecha_ingreso):"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle">Categoría de empleado:</p>
                        <p class="col-8 py-0 px-0 text"><span :class="empleado.empleadoCategoria != null ? '':'text-mute'">{{empleado.empleadoCategoria != null ? empleado.empleadoCategoria.nombre:"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle">Centro de costo:</p>
                        <p class="col-8 py-0 px-0 text"><span :class="empleado.centroCostos != null ? '':'text-mute'">{{empleado.centroCostos != null ? empleado.centroCostos.descripcion:"Información faltante*"}}</span></p>
                    </div>
                </div>
                <v-divider class="mt-5  divider-gray skiny"></v-divider>
                <p class="col-4 py-0 px-0 mt-7 subtitleBig">Nómina</p>
                <div class="mt-4">
                    <div class="row col-lg-12">
                        <p class="col-4 py-0 px-0 subtitle">Tipo de nómina:</p>
                        <p class="col-8 py-0 px-0 text"><span :class="empleado.tipoNomina != null ? '':'text-mute'">{{empleado.tipoNomina != null ? empleado.tipoNomina.descripcion:"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle">Régimen de contratación:</p>
                        <p class="col-8 py-0 px-0 text"><span :class="empleado.tipoRegimen != null ? '':'text-mute'">{{empleado.tipoRegimen != null ? empleado.tipoRegimen.descripcion:"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle">Régimen Fiscal:</p>
                        <p class="col-8 py-0 px-0 text"><span :class="empleado.regimenFiscal != null ? '':'text-mute'">{{empleado.regimenFiscal != null ? empleado.regimenFiscal.descripcion:"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle">Código postal del timbre:</p>
                        <p class="col-8 py-0 px-0 text"><span :class="empleado.codigoPostalTimbre.valor != null ? '':'text-mute'">{{ empleado.codigoPostalTimbre.valor != null ? empleado.codigoPostalTimbre.valor: "Información faltante*" }}</span></p>
                        <p class="col-4 py-0 px-0 subtitle" v-if="tipoDeRegimen">Sueldo diario:</p>
                        <p class="col-8 py-0 px-0 text" v-if="tipoDeRegimen"><span :class="empleado.salarioMinimo.nuevo_sueldo != null ? '':'text-mute'">{{empleado.salarioMinimo.nuevo_sueldo != null ? formatoMoneda(empleado.salarioMinimo.nuevo_sueldo):"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle" v-if="tipoDeRegimen">Sueldo mensual:</p>
                        <p class="col-8 py-0 px-0 text" v-if="tipoDeRegimen"><span :class="sueldoMensual != null ? '':'text-mute'">{{sueldoMensual != null ? formatoMoneda(sueldoMensual):"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle" v-if="tipoDeRegimen">Salario base cotización:</p>
                        <p class="col-8 py-0 px-0 text" v-if="tipoDeRegimen"><span :class="empleado.salarioMinimo.nuevo_sbc != null ? '':'text-mute'">{{empleado.salarioMinimo.nuevo_sbc != null ? formatoMoneda(empleado.salarioMinimo.nuevo_sbc):"Información faltante*"}}</span></p>
                        <p class="col-4 py-0 px-0 subtitle" v-if="tipoDeRegimen">Tipo de empleado:</p>
                        <p class="col-8 py-0 px-0 text" v-if="tipoDeRegimen"><span :class="empleado.empleadoTipo != null ? '':'text-mute'">{{ empleado.empleadoTipo != null ? empleado.empleadoTipo.descripcion:"Información faltante*" }}</span></p>
                        <p class="col-4 py-0 px-0 subtitle">Forma de pago:</p>
                        <p class="col-8 py-0 px-0 text"><span :class="empleado.tipo_pago != null ? '':'text-mute'">{{ formtoFormaPago(empleado.tipo_pago) }}</span></p>
                    </div>
                </div>
                <template v-if="tipoDeRegimen">
                    <v-divider class="mt-5  divider-gray skiny"></v-divider>
                    <p class="col-4 py-0 px-0 mt-7 subtitleBig">Prestaciones</p>
                    <div class="mt-4">
                        <div class="row col-lg-12">
                            <p class="col-4 py-0 px-0 subtitle">Número de INFONAVIT:</p>
                            <p class="col-8 py-0 px-0 text"><span :class="empleado.cuenta_infonavit != null ? '':'text-mute'">{{empleado.cuenta_infonavit != null ? empleado.cuenta_infonavit:"Información faltante*"}}</span></p>
                            <p class="col-4 py-0 px-0 subtitle">Fecha inicial de descuento:</p>
                            <p class="col-8 py-0 px-0 text"><span :class="empleado.fecha_inicio_descuento != null ? '':'text-mute'">{{empleado.fecha_inicio_descuento != null ? dateFormat(empleado.fecha_inicio_descuento):"Información faltante*"}}</span></p>
                            <p class="col-4 py-0 px-0 subtitle">Tipo de descuento:</p>
                            <p class="col-8 py-0 px-0 text"><span :class="empleado.tipo_factor != null ? '':'text-mute'">{{empleado.tipo_factor != null ? empleado.tipo_factor !== "CF"?"Factor de descuento":"Cuota fija":"Información faltante*"}}</span></p>
                            <p class="col-4 py-0 px-0 subtitle">Cantidad a descontar:</p>
                            <p class="col-8 py-0 px-0 text"><span :class="empleado.factor_descuento != null ? '':'text-mute'">{{empleado.factor_descuento != null ? empleado.tipo_factor !== "CF"?empleado.factor_descuento+" (VSM)":formatoMoneda(empleado.factor_descuento):"Información faltante*"}}</span></p>
                            <p class="col-4 py-0 px-0 subtitle">Fecha de alta IMSS:</p>
                            <p class="col-8 py-0 px-0 text"><span :class="empleado.fecha_alta_imss != null ? '':'text-mute'">{{empleado.fecha_alta_imss != null ? dateFormat(empleado.fecha_alta_imss):"Información faltante*"}}</span></p>
                            <p class="col-4 py-0 px-0 subtitle">Número FONACOT:</p>
                            <p class="col-8 py-0 px-0 text"><span :class="empleado.cuenta_fonacot != null ? '':'text-mute'">{{empleado.cuenta_fonacot != null ? empleado.cuenta_fonacot:"Información faltante*"}}</span></p>
                            <p class="col-4 py-0 px-0 subtitle">AFORE:</p>
                            <p class="col-8 py-0 px-0 text"><span :class="empleado.afore != null ? '':'text-mute'">{{empleado.afore != null ? empleado.afore:"Información faltante*"}}</span></p>
                        </div>
                    </div>
                
                
                    <v-divider class="mt-5  divider-gray skiny"></v-divider>
                    <p class="col-4 py-0 px-0 mt-7 subtitleBig">Cursos - Capacitaciones</p>
                    <div class="mt-4">
                        <div class="row col-lg-12">
                            <p class="col-4 py-0 px-0 subtitle">Brigada:</p>
                            <p class="col-8 py-0 px-0 text"><span :class="empleado.brigada != null ? '':'text-mute'">{{empleado.brigada!= null ? empleado.brigada.nombre:"Información faltante*"}}</span></p>
                            <template v-if="empleado.cursosCapacitaciones.length == 0">
                                <p class="col-4 py-0 px-0 subtitle">Curso - capacitación:</p>
                                <p class="col-8 py-0 px-0 text-mute">Sin capacitaciones asignadas*</p>
                            </template>
                            <template v-else v-for="(curso,index) in empleado.cursosCapacitaciones">
                                <p v-if="empleado.cursosCapacitaciones.length == 1" class="col-4 py-0 px-0 subtitle" :key="index">Curso - capacitación en:</p>
                                <p v-else class="col-4 py-0 px-0 subtitle" :key="index">Curso - capacitación {{index+1}} en:</p>
                                <p class="col-8 py-0 px-0 text" :key="index"><span :class="curso.cursoCapacitacion != null ? '':'text-mute'">{{curso.cursoCapacitacion != null ? curso.cursoCapacitacion.nombre:"Información faltante*"}}</span></p>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-if="historico_empleado.length > 0 && !empleado.asimilado">
                <v-divider class="mt-5  divider-gray skiny"></v-divider>
                <p class="col-4 py-0 px-0 mt-7 subtitleBig">Historial de movimientos</p>
                <div class="mt-4">
                    <div class="row col-lg-12">
                       <table style="width:648px; margin-left:-8px;" >
                           <thead> 
                               <tr><td colspan="4"><v-divider style="border:1px solid #E0E0E0;"></v-divider></td></tr>
                               <tr class="tableTdTitle">
                                   <td>MOTIVO</td>
                                   <td>Tipo de baja ante el IMSS</td>
                                   <td>Fecha</td>
                                   <td>Descripción</td>
                               </tr>
                               <tr><td colspan="4"><v-divider style="border:1px solid #E0E0E0;"></v-divider></td></tr>
                           </thead>
                           <tbody>
                               <template v-for="(item, index) in historico_empleado" >
                                    <tr class="tableTdText" :key="index">
                                        <td>
                                            <div class="subtitle">{{ item.accion }}</div>
                                        </td >
                                        <td>
                                            <div>{{ item.nombre_tipo_baja }}</div>
                                        </td>
                                        <td>
                                            <div>{{ dateFormat(item.fecha) }}</div>
                                        </td>
                                        <td>
                                            <div>{{ item.descripcion }}</div>
                                        </td>
                                    </tr>
                                   <tr :key="index"><td colspan="4"><v-divider style="border: 1px solid #F2F2F2;"></v-divider></td></tr>
                               </template>
                           </tbody>
                       </table>
                    </div>
                </div>
                </template>
            </div>
        </div>
    </v-container>   
</template>

<script>

import apiEmpleadosInactivos from '@/api/empleadosInactivos';
import queries from '@/queries/Kardex';
import GetUrlImg from '@/plugins/Urlimagen';
import gFunctions from '@/js/global_functions';
import tipoRegimen from '../../../api/nominas/tipoRegimen';
import apiRegistroPatronal from "@/api/nominas/registroPatronal";

export default {
    components: {
    },
    props: ['objEmpleado'],
    data() {
        return {
            empleado: {
                id                      : null,
                nombre                  : '',
                apellido_paterno        :null,
                apellido_materno        :null,
                fecha_nacimiento        :null,
                sexo                    :"M",
                direccion               :null,
                estado_id               :null,
                municipio_id            :null,
                cp_id                   :null,
                colonia                 :{nombre:null},
                colonia_id              :null,
                telefono                :"",
                correo                  :null,
                estadoCivil            :{nombre:null},
                estado_civil_id         :null,
                fecha_ingreso           :null,
                fecha_baja              :'',
                dia_descanso            :"0",
                cliente_id              :null,
                empresa_id              :null,
                sucursal                :{nombre:null},
                sucursal_id             :null,
                departamento            :{nombre:null},
                departamento_id         :null,
                horario                 :{nombre:null},
                horario_id              :null,
                tiene_hijos             :false,
                clave_interna           :null,
                email_anterior          :null,
                dias_vacaciones         :null,
                prestacion_id           :null,

                rfc                     :null,
                curp                    :null,
                tipoContrato           :{descripcion:null},
                tipo_contrato_id        :null,
                salario_diario          :null,
                salario_diario_fiscal   :null,
                imss                    :null,
                fecha_alta_imss         :null,
                fecha_inicio_descuento  :null,
                factor_descuento        :null,
                tipo_descuento          :null,
                tipo_sangre             :null,
                fecha_planta            :null,
                cuenta_fonacot          :null,
                cuenta_infonavit        :null,
                afore                   :null,
                nombre_contacto         :null,
                telefono_contacto       :null,
                observaciones           :null,
                estudios:{nombre:null},
                nivel_estudios_id       :null,
                puesto:{nombre:null},
                puesto_id               :null,
                cuentasBancarias        :[],
                foto                    :null,
                foto_default            :null,
                empleadoEmpresas        :[],
                empresa:{nombre:null},
                tipoNomina:{descripcion:null},
                tipo_nomina_id          :null,
                registroPatronal        :{nombre:null},
                registro_patronal_id    :null,
                entidad_cfdi            :null,
                tipoRegimen            :{
                    clave:null,
                    descripcion:null
                },
                tipo_regimen_sat_id     :null,
                brigada                 :{nombre:null},
                brigada_proteccion_civil_id :null,
                ranking:{
                    clima_laboral       : false,
                    proyectos_mejoras   : false
                },
                codigoPostal            :{nombre:null},
                cursosCapacitaciones   : [],  
                nacionalidad            : null,
                lugar_nacimiento        : null,
                celular                 : null,
                observacion_salud       : null,
                conocimientos_extras    : null,
                direccion_laboral       : null,
                empleadoTipo            : {descripcion:null},
                regimenFiscal           : {descripcion : null},
                codigoPostalTimbre      : {valor:null},
                salarioMinimo           : {
                    nuevo_sbc_fijo      : null,
                    nuevo_sbc_variable  : null,
                    fecha_de_aplicacion : null,
                    nuevo_sbc           : null,
                },
            },
            urlImg : null,
            cargaModal: true,
            historico_empleado : [],
            factorCalculo : 0,
            
            //vuetify money configuration
           
        }
    },
    computed: {
        sueldoMensual: function(){
            return parseFloat(this.empleado.salarioMinimo.nuevo_sueldo) * this.factorCalculo;
        },
        tipoDeRegimen: function(){
            if(
                this.empleado.tipoRegimen.clave == '05' || 
                this.empleado.tipoRegimen.clave == '06' ||
                this.empleado.tipoRegimen.clave == '07' ||
                this.empleado.tipoRegimen.clave == '08' ||
                this.empleado.tipoRegimen.clave == '09' ||
                this.empleado.tipoRegimen.clave == '10' ||
                this.empleado.tipoRegimen.clave == '11'
            ){
                return false;
            }
            else{
                return true;
            }
        }
        
    },
    watch: {
        objEmpleado:{
            deep:true,
            handler(){
                //this.cargaModal = true;
            }
        },
        'empleado.registro_patronal_id' : function(newVal,oldVal){
            this.getFactorCalculo()
        },
    },
    methods: {
        getHitorico(){
            apiEmpleadosInactivos.getHistorico({empleado_id:this.objEmpleado.id})
            .then((response)=>{
                this.historico_empleado = response.data;
            })
            .catch(err => {
                this.isSaving = false;
                gFunctions.errorParse(err.response.data.error);
            });
        },
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        
        
        close() {
            this.$modal.hide('kardexModal');
        },
        scrollPerfect(){
           
            
        },
        formatoMoneda(value){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(value)
        },
        formtoFormaPago(formaPago = null){
            switch (formaPago) {
                case "bancario":
                    return "Transferencia bancaria"                    
                    break;
                case "stp":
                    return "Transferencia STP"
                    break;
                case "otros":
                    return "Otros"
                    break;
                case "efectivo":
                    return "Efectivo"
                    break;
                case "cheque":
                    return "Cheque"
                    break;
                default:
                    return "Información faltante*";
                    break;
            }
        },
        async getFactorCalculo(){
            if(this.empleado.registro_patronal_id == null || this.empleado.registro_patronal_id == ''){
                return;
            }
            await apiRegistroPatronal.factorCalculo(this.empleado.registro_patronal_id).then((response) => {
                this.factorCalculo = response.valor;
            })
            .catch(err => {      
                console.log("Error factor cálculo");
            });
        },
    },
    apollo: {
        empleado: {
            query       : queries.getEmpleadoQuery,
            fetchPolicy : 'no-cache',
            variables() {
                return {
                    id : parseInt(this.objEmpleado.id)
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                //this.getParam();
                //this.getCP(this.empleado.cp_id);
                this.cargaModal = false;
            }

        },
      
    },
    mounted() {
        let self = this;

        this.getHitorico();

        
        
        this.urlImg = GetUrlImg.getURL();
    },

    async created(){   
        this.cargaModal = true;
        if(this.objEmpleado != null) {
            this.$apollo.queries.empleado.skip = false;
        } 
    }
}
</script>

<style scoped>
    .tableTdTitle > td{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
         padding-top: 2px ;
        padding-bottom: 2px ;
    }
    .tableTdText > td{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
        padding-top: 0px ;
        padding-bottom: 0px ;
    }
    .content{
        padding-left: 50px;
        padding-right: 50px;
    }
    .divider-blue{
        z-index: 999 !important;
        max-width: 102px;
        border-radius: 50px;
        background-color: #2DB9D1;
        border: 1.5px solid #2DB9D1;
    }
    .divider-gray{
        margin-left:-1px;
        border: 1.5px solid #E0E0E0;
        background:#E0E0E0;
    }
    .skiny{
        margin-top:1px;
        border: .5px solid #E0E0E0;
    }
    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
    }
    .subtitleBig{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        color: #333333;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
    }
    .text-mute{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
    }
    #loading{
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .header {
        width: 625px;
        text-align: left !important;
        margin-bottom:15px;
    }

    .title {
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: 22px !important;
        line-height: 27px !important;
        letter-spacing: 2px !important;
        color: #333333 !important;
    }
    .text-green input{
        color: green !important;
    }
    .tituloRadio{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #A0A4A8;
        padding-bottom: 10px;
    }
   

    /**
     * TABLE DOCUMENTOS
     */
     th, td {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;

        text-align: left !important;
    }

    tbody tr:nth-child(1n){
        /* background-color: #f2f2f2; */
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }

    .tbl-documentos {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-documentos .tbl-header {
        background: #F7F7F7;
        border-radius: 20px;
        /* border-top-left-radius: 20px;
        border-top-right-radius: 20px; */
    }
    .scrollbar-template2 {
        position: relative;
    }
    .cursor-pointer{
      cursor:pointer;
    }
    #btnModal_agregar{
      display: none;
    }
    /* Estilos subir imagen */
    .btn-group {
        min-width: 80px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }
    .btn-group a:hover {
        text-decoration: none;
    }
    .dropdown-toggle {
        font-family: "Montserrat" !important;
        font-size: 16px !important;
        border: 0.5px solid rgba(0, 0, 0, 0.38);
        box-sizing: border-box;
        border-radius: 8px;
        padding: 9px 45px 8px 15px;
        width: 248px !important;
        height: 56px !important;
        outline: 0;
        color: rgba(0, 0, 0, 0.6);
        min-width: 80px;
        background-color: #FFFFFF;
        transition: background 0s ease-out;
        float: none;
        box-shadow: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        letter-spacing: normal;
        text-align: left;
        line-height: 36px;
    }
    .dropdown-toggle:hover {
        border: 0.5px solid rgba(0, 0, 0, 0.87);
        color: rgba(0, 0, 0, 0.87);
        background: #FFFFFF;
        cursor: pointer;
    }
    .dropdown-menu {
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 1000;
        float: left;
        min-width: 260px;
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: 14px;
        text-align: left;
        background-color: #FFF;
    }
    .dropdown-menu > li > a {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #96999A;
        padding: 10px 30px;
        display: block;
        clear: both;
        white-space: nowrap;
        text-decoration: none;
    }
    .dropdown-menu > li > a:hover {
        background: #96999A;
    }
    .dropdown-menu > li {
        overflow: hidden;
        width: 100%;
        position: relative;
        margin-bottom: -29px;
    }
    .caret {
        color: #96999A;
        font-size: 18px;
        position: absolute;
        top: 7px;
        margin-left: -24px;
        margin-top: 4px;
        vertical-align: middle;
        right: 10px;
        line-height: 36px;
    }
    li {
        list-style: none;
    }
    .textoFoto {
        width: 284px;
        height: 30px;
        left: 218px;
        top: 518px;
        padding-left: 0px 65px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        color: #96999A;
    }
    .btn-contenedor{
        padding-left: 20%;
        padding-right: 20%;
        text-align: center; 
        display: grid;
    }
    .btn-capturar{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.2px;
        display: inline-block !important;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        background: #007EFF !important;
        box-shadow: 0px 8px 16px rgba(0, 126, 255, 0.16), inset 10px 11px 17px rgba(119, 124, 185, 0.11);
        border-radius: 80px;
        text-transform: none;
        margin-bottom: 5px !important;
    }
    .btn-omitir{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: inline-block !important;
        align-items: center;
        text-align: center;
        color: #96999A;
        background: transparent !important;
        border-radius: 80px;
        box-shadow: none !important;
        text-transform: none;
    }
    .imgFoto {
        width: 100%;
        height: 100%;
    }
    .imgMarco{
        width: 191px;
        height: 199px;
        padding:18px;
        border: 3px solid #E0E0E0;
        box-sizing: border-box;
    }
    .titulo-foto {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #004baf;
        margin-top: 5px !important;
        margin-left: 10px !important;
        float: left;
    }
    /* Fin estilos subir imagen */
    .v-btn-cerrar .v-btn:hover::before {
      background-color: transparent !important;
      pointer-events: none;
    }
    .v-btn-cerrar .v-btn:hover::after {
      background-color: transparent !important;
      pointer-events: none;
    }
    .v-btn-cerrar .v-btn:hover {
      background-color: transparent !important;
      pointer-events: none;
    }
</style>