import Vue from 'vue';

export default {
    consultar(parametros) {
        return Vue.axios.post("empleados/incremento-salarios", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    guardar(parametros) {
        return Vue.axios.post("empleados/guardar-incremento-salarios", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    historial(parametros) {
        return Vue.axios.get("empleados/historial-salarios/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateHistorial(parametros) {
        return Vue.axios.put("empleados/historial-salarios", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async eliminarRegistro(id) {
        return Vue.axios.delete("empleados/historial-salarios/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async consultarPreSBCF(parametros) {
        return Vue.axios.post("empleados/preview-sbc-fijo", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async consultarSinEmpleados(parametros) {
        return Vue.axios.get("empleados/calculo-incremento", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

}