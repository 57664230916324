import Vue from 'vue';

export default {
    getLast(id){
        return Vue.axios.get("registros-checadores/last-id/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getHistorico(parametros){
        return Vue.axios.get("registros-checadores/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateDataBase(parametros){
        return Vue.axios.post("registros-checadores/create-asp", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getAsistencias(parametros){
        return Vue.axios.get("empleados/find-checked", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateHistorico(parametros){
        return Vue.axios.get("registros-checadores/historico",{ params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros){
        return Vue.axios.post("registros-checadores", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    checarCodigo(parametros){
        return Vue.axios.post("reloj-checador/codigo-verificacion", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    generarCodigo(parametros){
        return Vue.axios.post("reloj-checador/codigo-verificacion/generar", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getCodigo(id){
        return Vue.axios.get("reloj-checador/codigo-verificacion/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    uploadImageCliker(parametros){
        return Vue.axios.post("registros-checadores/temporales", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    asistenciaAWS(parametros){
        return Vue.axios.post("registros-checadores/validateface", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}