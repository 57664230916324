<template>
    <v-row>
        <ValidationObserver ref="form" v-slot="{ invalid }" class="displayTemplate">
            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                    <v-text-field
                        v-model="departamento.nombre"
                        outlined
                        autofocus
                        id="primero"
                        label="Nombre"
                        :error-messages="errors"
                        required
                    />
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Encargado" rules="required">
                    <v-text-field
                        v-model="departamento.encargado"
                        outlined
                        label="Encargado"
                        :error-messages="errors"
                        required
                    />
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                <div class="textoCorreos">Ingresa los correos de personas que darán seguimiento a las solicitudes del departamento:</div>
                <ValidationProvider v-slot="{ errors }" name="Emails" rules="required">
                    <v-textarea
                        v-model="departamento.correo"
                        outlined
                        label="Emails"
                        :error-messages="errors"
                        required
                        rows="3"
                        :persistent-placeholder="true"
                        placeholder="jackson.graham@example.com, debra.hold@example.com, michelle.rivera@example.com, geogia.young@example.com"
                    />
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                <v-textarea
                    outlined
                    label="Descripción"
                    v-model="departamento.descripcion"
                ></v-textarea>
            </v-col>
        </ValidationObserver>
    </v-row>             
</template>
<script>
import clienteAPi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import sucursalApi from "@/api/sucursales";
import departamentoApi from "@/api/departamentos";
import Notify from "@/plugins/notify";

export default {
    name: "pop-departamento",
    props: {
        oyente: Boolean,
        reset:Boolean,
    },
    data() {
        return {
            loading_guardar: false,
            dialog: false,
            datosLogin: "",
            departamento: {
                id: 0,
                nombre: "",
                encargado: "",
                correo: "",
                descripcion: "",
                sucursal_id: null,
                empresa_id: null,
                cliente_id: null,
                formValidate:false,
                status:'depto'
            },
            clientes: [],
            empresas: [],
            sucursales: [],
            titulo_modal: "",
            finaliza_carga: false,
            parametros_base: { activo: true, paginate: false },
            accion: "",
            modalDias: false,
            modalRegistro: false,
            dias_festivos: [],
            dias: false,
            cliente_value: null,
            empresa_value: null,
            sucursal_value: null,
            rol: null,
            conteo_parametros: 0,
            imagen_depto: "/static/modal/departamento.png",
            anio_Actual: "",
        };
    },
    watch: {
        cliente_value: function(val) {
            this.cambiaCliente(val);
        },
        empresa_value: function(val) {
            this.cambiaEmpresa(val);
        },
        oyente: function(val) {
            if (val) {
                this.recopilaDatos();
            }
        },
        reset: function(val){
            if(val){
                this.resetValue();
                this.loadData();
                this.$nextTick(() => {
                    this.$refs.form.reset();
                });
            }
        }
    },
    methods: {
        recopilaDatos() {
            this.$refs.form.validate().then(success => {
                if (!success) {
                    this.$emit("resetValorGuardar"); 
                    return;
                }
                this.guardar();
                this.departamento.cliente_id = this.cliente_value;
                this.departamento.empresa_id = this.empresa_value;
                this.departamento.sucursal_id =  this.sucursal_value;
                if (success) {
                    this.departamento.formValidate = this.departamento.formValidate
                        ? false
                        : this.departamento.formValidate;
                } else {
                    this.departamento.formValidate = true;
                }
                this.$emit("save", this.departamento);
            });
        },
        listar() {
            this.resetValue();
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;


            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id);
                this.cliente_value = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.cambiaEmpresa(this.datosLogin.empresa_id);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }

            if (this.rol == "admin") {
                this.departamento.cliente_id = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.departamento.empresa_id = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                this.departamento.sucursal_id = this.datosLogin.sucursal_id;
            }
            
        },

        loadData() {
            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id);
                this.cliente_value = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.cambiaEmpresa(this.datosLogin.empresa_id);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },


        async abrirModal(accion, item = null) {
            this.resetValue();
            this.errors.clear();
            this.accion = accion;
            this.finaliza_carga = false;
            
            if (accion == "add") {
                this.titulo_modal = "Registrar departamento";
                this.imagen_depto = "/static/modal/departamento.png";
            } 
            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id);
                this.cliente_value = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.cambiaEmpresa(this.datosLogin.empresa_id);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }  
        },
        getClientes(cliente = null) {
        let param = { activo: true, paginate: false };
        clienteAPi
            .getClientes(param)
            .then((response) => {
            this.clientes = response.data;
            this.finaliza_parametros();
            })
            .catch((error) => {
            console.error(err);
            Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },
        cambiaCliente(id, cliente = null) {
            if (this.departamento.cliente_id != id) {
                this.empresa_value = null;
                this.departamento.empresa_id = null;
            }

            let parametros = { activo: true, paginate: false, cliente_id: id };
            empresaApi
                .getEmpresas(parametros)
                .then((response) => {
                    this.empresas = response.data;
                    if (this.departamento.empresa_id != null) {
                        this.empresa_value = this.departamento.empresa_id;
                        this.finaliza_parametros();
                    }
                })
                .catch((error) => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
                });
        },
        cambiaEmpresa(id, sucursal = null) {
            if (this.departamento.empresa_id != id) {
                this.sucursal_value = null;
                this.departamento.sucursal_id = null;
            }
            let parametros = { activo: true, paginate: false, empresa_id: id };
            sucursalApi.getSucursales(parametros).then((response) => {
                this.sucursales = response.data;
                    if (this.departamento.sucursal_id != null) {
                        this.sucursal_value = this.departamento.sucursal_id;
                    }
            })
            .catch((error) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                if (success) {
                    this.loading_guardar = true;
                    if (this.rol == "admin") {
                        this.cliente_value = this.datosLogin.cliente_id;
                    } else if (this.rol == "admin-empresa") {
                        this.cliente_value = this.datosLogin.cliente_id;
                        this.empresa_value = this.datosLogin.empresa_id;
                    } else if (this.rol == "admin-sucursal") {
                        this.cliente_value = this.datosLogin.cliente_id;
                        this.empresa_value = this.datosLogin.empresa_id;
                        this.sucursal_value = this.datosLogin.sucursal_id;
                    }

                    this.departamento.cliente_id = this.cliente_value;
                    this.departamento.empresa_id = this.empresa_value;
                    this.departamento.sucursal_id = this.sucursal_value;

                    if (this.accion === "add") {
                        departamentoApi.addDepartamento(this.departamento).then((response) => {
                            this.$refs.tableDepartamentos.getData();
                            this.respuesta_ok(
                                "El nuevo departamento se ha guardado satisfactoriamente."
                            );
                        })
                        .catch((error) => {
                            this.respuesta_error(error);
                        });
                    } else if (this.accion === "update") {
                        departamentoApi.updateDepartamento(this.departamento).then((response) => {
                            this.$refs.tableDepartamentos.getData();
                            this.respuesta_ok(
                                "El departamento se actualizó satisfactoriamente."
                            );
                        })
                        .catch((error) => {
                            this.respuesta_error(error);
                        });
                    }
                }
            });
        },
        respuesta_error(err) {
            this.loading_guardar = false;
            let error = Object.values(err.response.data.error);
            let leyenda = ``;
            for (var i = 0; i < error.length; i++) {
                leyenda += `* ` + error[i] + `\n`;
            }

            Notify.ErrorToast(leyenda);
        },
        cerrarModal() {
            this.dialog = false;
            this.resetValue();
        },
        resetValue() {
            this.departamento = {
                id: 0,
                nombre: "",
                encargado: "",
                correo: "",
                descripcion: "",
                sucursal_id: null,
                empresa_id: null,
                cliente_id: null,
                formValidate:false,
                status:'depto'
            };
            this.value = null;
            this.dias_festivos = [];
            this.cliente_value = null;
            this.empresa_value = null;
            this.sucursal_value = null;
            this.conteo_parametros = 0;
            this.loading_guardar = false;
            this.clientes = [];
            this.empresas = [];
            this.sucursales = [];
        },
        finaliza_parametros() {
            this.conteo_parametros = this.conteo_parametros + 1;
            if (this.accion == "add" && this.conteo_parametros >= 2) {
                this.finaliza_carga = true;
            }

            if (
                this.accion == "update" &&
                this.conteo_parametros >= 3 &&
                (this.rol == "root" ||
                this.rol == "admin" ||
                this.rol == "admin-empresa")
            ) {
                this.finaliza_carga = true;
            }
            if (
                this.accion == "update" &&
                this.conteo_parametros >= 1 &&
                this.rol == "admin-sucursal"
            ) {
                this.finaliza_carga = true;
            }
        },
        respuesta_ok(msj) {
            this.listar();
            this.cerrarModal();
            this.resetValue();
            this.dialog = false;
            Notify.Success("Operación exitosa", msj);
        },
        init() {
            var hoy = new Date();
            this.anio_Actual = hoy.getFullYear();
        },
    },
    mounted() {
        this.init();
    },
    created() {
        this.listar();
    },
};
</script>

<style scoped>
    .displayTemplate{
        display: contents;
    }
    .textoCorreos{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
        padding-bottom: 10px;
    }
</style>