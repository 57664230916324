<template>
    <div class="component-container">
        <header class="card-header">
            <ul class="tab-heads">
                <li v-for="(tab, index) in tabs" class="tab-head" v-bind:key="tab.name" v-on:click="switchTab(index)">
                    <div v-if="tab.isActive" class="active-border"></div>
                    <span class="tab-title" :class="{ 'is-active': tab.isActive }">{{ tab.name }}</span>
                </li>
            </ul>
        </header>
        <div class="tab-panel">
            <slot></slot>
        </div>
        <div class="button-group">
            <div class="row">
                <div class="col-25">
                    <button @click="saveDraft()"  v-if='showDraft' class="button-default pull-left">Guardar borrador</button>
                </div>

                <div class="col-75" v-if="isWizard">
                   <button v-if="currentActive == totalTabs -1" @click="submit()" class="btnGuardar modal-pull-right" :disabled="loadingGuardar">
                        <template v-if="!loadingGuardar">
                            Guardar
                        </template>
                        <template v-else>
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        </template>
                    </button>
                    
                    <button v-if="currentActive < totalTabs - 1" @click="nextTab()" class="btnGuardar modal-pull-right">Siguiente</button>
                    <button class="btnCerrar modal-pull-right" :disabled="loadingGuardar" @click="cancel()">Cancelar</button>
                </div>

                <div class="col-75" v-else>
                    <button @click="submit()" class="btnGuardar modal-pull-right" :disabled="loadingGuardar">
                        <template v-if="!loadingGuardar">
                            Guardar
                        </template>
                        <template v-else>
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        </template>
                    </button>
                    <button class="btnCerrar modal-pull-right"  @click="cancel()">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'form-wizard',
    props: {
        selectedIndex: {
            type: Number,
            default: 0
        },
        showDraft: {
            type: Boolean,
            default: true
        },
        isWizard: {
            type: Boolean,
            default: true
        },
        loadingGuardar: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return{
            tabs            : [],
            currentActive   : 0,
            totalTabs       : 0,
        }
    },
    created() {
        this.tabs = this.$children;
    },
    mounted() {
        this.totalTabs = this.tabs.length;

        if(this.selectedIndex) {
            this.switchTab(this.selectedIndex);
        }
    },
    methods: {
        switchTab(index) {
            this.currentActive = index;
            this.tabs.forEach(tab => {
                tab.isActive = false;
            });

            this.tabs[this.currentActive].isActive = true;
        },
        previousTab() {
            this.currentActive--;
            this.tabs.forEach(tab => {
                tab.isActive = false;
            });

            this.tabs[this.currentActive].isActive = true;
        },
        nextTab() {
            this.currentActive++;
            this.$emit("scrollTop");
            this.tabs.forEach(tab => {
                tab.isActive = false;
            });

            this.tabs[this.currentActive].isActive = true;
        },
        submit() {
            this.$parent.$parent.$refs.form.validate().then(valid => {
                this.$emit("onSubmit", valid);
            });
        },
        saveDraft() {
            this.$emit("onSaveDraft");
        },
        cancel() {
            this.$emit("onCancel");
        }
    }
}
</script>

<style scoped>
    .pull-left {
        float: left;
    }

    .pull-right {
        float: right;
    }

    .pr-25px {
        padding-right: 25px;
    }

    .pt-3per {
        padding-top: 3%;
    }

    .component-container {
        background-color: #fff;
        padding: 50px 50px;
        width: 100%;
    }

    .card-header {
        overflow-x: auto;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    span.tab-title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #BDBDBD;
    }

    .tab-heads {
        display: flex;
        list-style: none;
        padding-top: 20px;
        padding-left: 0px !important;

        border-top: 1px solid #ACB6BB;
    }

    li.tab-head {
        width: 50%;
        position: relative;
        cursor: pointer;
    }

    span.is-active {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #1E2245;

        /* border-top-width: 8px;
        border-radius: 5px;
        border-top-style: solid; 
        border-top-color: #004BAF !important; */
    }

    .active-border {
        width: 180px !important;
        /* margin-top: -24px !important; */
        top: -25px;
        position: absolute;

        border-top-width: 8px;
        border-radius: 5px;
        border-top-style: solid; 
        border-top-color: #2DB9D1 !important;
    }

    /* span.is-active::after {
        transition: 0.4s;
        border-top-width: 8px;
        border-top-style: solid; 
        border-radius: 5px;
        border-top-color: #004BAF !important;
    } */

    .button-group {
        border-radius: 5px;
        padding: 20px;
    }

    .col-25 {
        float: left;
        width: 25%;
        margin-top: 6px;
    }

    .col-75 {
        float: left;
        width: 75%;
        margin-top: 3px;
    }

    button:focus {
        outline: 0;
    }

    .button-primary {
        background: #004BAF;
        box-shadow: 0px 8px 16px rgba(0, 126, 255, 0.16);
        border-radius: 80px;
        padding: 15px 24px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.2px;

        color: #FFFFFF;
    }

    .button-default {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 18px !important;
        text-align: center !important;
        letter-spacing: 0.2px !important;
        background-color: transparent !important;
        color: #a0a4a8 !important;
        outline: none;
        border-radius: 30px !important;
        width: 200px !important;
        height: 40px !important;
        margin-bottom: 4px;

    }

    .button-default:hover {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 18px !important;
        text-align: center !important;
        letter-spacing: 0.2px !important;
        background-color: #c7cacc !important;
        color: #FFF !important;
        outline: none;
        border-radius: 30px !important;
        width: 200px !important;
        height: 40px !important;
        margin-bottom: 4px;
    }
</style>
