<template>
    <v-row>
        <ValidationObserver ref="form" v-slot="{ invalid }" class="displayTemplate">
            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                    <v-text-field 
                        outlined 
                        label="Nombre de puesto" 
                        class="pa-0 ma-0" 
                        v-model="puesto.nombre" 
                        :error-messages="errors" 
                        required
                    >
                    </v-text-field>
                </ValidationProvider>
            </v-col>
            <!--<v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Objeto SYS" rules="required">
                    <v-text-field 
                        outlined 
                        label="Objeto SYS" 
                        class="pa-0 ma-0" 
                        v-model="puesto.objetivo" 
                        :error-messages="errors" 
                        required
                    >   
                    </v-text-field>
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                <v-text-field 
                    outlined 
                    label="Objeto IAS" 
                    class="pa-0 ma-0" 
                    v-model="puesto.objetivo_ias" 
                >
                </v-text-field>
            </v-col>-->
            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Funciones" rules="required">
                    <v-textarea 
                        outlined 
                        label="Funciones" 
                        class="pa-0 ma-0" 
                        v-model="puesto.funciones" 
                        :error-messages="errors" 
                        required
                    >
                    </v-textarea>
                </ValidationProvider>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="5" class="py-0">
                <VuetifyMoneyValidate
                    rules="required"
                    outlined
                    label="Sueldo mínimo sugerido"
                    prefix="$"
                    v-model="puesto.sueldo"
                    v-bind:options="options"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                <VuetifyMoneyValidate
                    rules="required"
                    outlined
                    label="Sueldo máximo"
                    prefix="$"
                    v-model="puesto.sueldo_maximo"
                    v-bind:options="options"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Plazas" rules="required|numeros_positivos">
                    <v-text-field 
                        outlined 
                        label="Plazas" 
                        v-model="puesto.plazas" 
                        :error-messages="errors" 
                        required
                        v-mask="'######'"
                    >
                    </v-text-field>
                </ValidationProvider>
            </v-col>
        </ValidationObserver>
    </v-row>
</template>


<script>
import Vue from "vue";
import Swal from "sweetalert2";
import apiPuestos from "@/api/nominas/puestos";
import Notify from "@/plugins/notify";
import clienteAPi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import sucursalApi from "@/api/sucursales";
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';

export default {
  name:'pop-puesto',
  props:{
    oyente:Boolean,
    reset:Boolean
  },
  components:{
      VuetifyMoneyValidate,
  },
  data() {
    return {
        loading_guardar: false,
        loading: true,
        dialog: false,
        search: "",
        datosLogin: "",
        puesto: {
            id: null,
            cliente_id: null,
            empresa_id: null,
            sucursal_id: null,
            nombre: "",
            objetivo: "",
            objetivo_ias: null,
            funciones: "",
            sueldo: null,
            sueldo_maximo: null,
            plazas: null,
            formValidate:false,
            status:'puesto'
        },
        titulo_modal: "",
        action: "",
        clientes: [],
        empresas: [],
        sucursales: [],
        cliente_value: null,
        empresa_value: null,
        sucursal_value: null,
        rol: null,
        imgModal: "",
        options: {
            locale: "es-MX",
            prefix: "$",
            suffix: "",
            length: 11,
            precision: 2
        },
    };
  },
  watch: {
    cliente_value: function(val) {
      this.cambiaCliente(val);
    },
    empresa_value: function(val) {
      this.cambiaEmpresa(val);
    },
    oyente: function(val){
      if(val){
        this.recopilaDatos();
      }
    },
    reset: function(val){
      if(val){
        this.resetValues();
        this.cargar();
      }
    }
  },
  methods: {
    recopilaDatos(){
      this.puesto.cliente_id = this.cliente_value;
      this.puesto.empresa_id = this.empresa_value;
      this.puesto.sucursal_id = this.sucursal_value;
      this.$refs.form.validate().then(success => {
          if (!success) {
              this.$emit("resetValorGuardar"); 
              return;
          }
                
          if(success){
              this.puesto.formValidate = (this.puesto.formValidate) ? false : this.puesto.formValidate;                    
          }
          else{
              this.puesto.formValidate = true;
          }
          this.$emit("save",this.puesto);                
      });            
    },
    
    resetValues() {
        this.puesto = {
            id: null,
            cliente_id: null,
            empresa_id: null,
            sucursal_id: null,
            nombre: "",
            objetivo: "",
            objetivo_ias: null,
            funciones: "",
            sueldo: null,
            sueldo_maximo: null,
            plazas: null,
            formValidate:false,
            status:'puesto'
        };
        this.cliente_value = null;
        this.empresa_value = null;
        this.sucursal_value = null;
        this.loading_guardar = false;
    },
    getClientes(cliente = null) {
        let param = { activo: true, paginate: false };
        clienteAPi.getClientes(param).then((response) => {
            this.clientes = response.data;
        })
        .catch((err) => {
            this.resetValues();
            let error = Object.values(err.response.data.error);
            let leyenda = ``;

            for (var i = 0; i < error.length; i++) {
                leyenda += `* ` + error[i] + `\n`;
            }

            Notify.ErrorToast(leyenda);
        });
    },
    cambiaCliente(id, cliente = null) {
      if (this.puesto.cliente_id != id) {
        this.empresa_value = null;
        this.puesto.empresa_id = null;
      }

      let parametros = { activo: true, paginate: false, cliente_id: id };
      empresaApi
        .getEmpresas(parametros)
        .then((response) => {
          this.empresas = response.data;
          if (this.puesto.empresa_id != null) {
            this.empresa_value = this.puesto.empresa_id;
          }
        })
        .catch((err) => {
          this.resetValues();
          let error = Object.values(err.response.data.error);
          let leyenda = ``;

          for (var i = 0; i < error.length; i++) {
            leyenda += `* ` + error[i] + `\n`;
          }

          Notify.ErrorToast(leyenda);
        });
    },
    cambiaEmpresa(id, sucursal = null) {
      if (this.puesto.empresa_id != id) {
        this.sucursal_value = null;
        this.puesto.sucursal_id = null;
      }
      let parametros = { activo: true, paginate: false, empresa_id: id };
      sucursalApi
        .getSucursales(parametros)
        .then((response) => {
          this.sucursales = response.data;
          if (this.puesto.sucursal_id != null) {
            this.sucursal_value = this.puesto.sucursal_id;
          }
        })
        .catch((err) => {
          this.resetValues();
          let error = Object.values(err.response.data.error);
          let leyenda = ``;

          for (var i = 0; i < error.length; i++) {
            leyenda += `* ` + error[i] + `\n`;
          }

          Notify.ErrorToast(leyenda);
        });
    },

    respuesta_error(err) {
      this.loading_guardar = false;
      let error = Object.values(err.response.data.error);
      let leyenda = ``;
      for (var i = 0; i < error.length; i++) {
        leyenda += `* ` + error[i] + `\n`;
      }

      Notify.ErrorToast(leyenda);
    },
    respuesta_ok(msj) {
      this.cerrarModal();
      Notify.Success("Operación exitosa", msj);
    },
    cargar(){
      this.datosLogin = this.$session.get("usuario");
      this.rol = this.datosLogin.rol.name;
      if (this.rol == "root") {
        this.getClientes();
      }
      if (this.rol == "admin") {
        this.cambiaCliente(this.datosLogin.cliente_id, this.accion);
        this.cliente_value = this.datosLogin.cliente_id;
      }
      if (this.rol == "admin-empresa") {
        this.cambiaEmpresa(this.datosLogin.empresa_id, this.accion);
        this.cliente_value = this.datosLogin.cliente_id;
        this.empresa_value = this.datosLogin.empresa_id;
      }
      if (this.rol == "admin-sucursal"){
        this.puesto.cliente_id = this.cliente_value = this.datosLogin.cliente_id;
        this.puesto.empresa_id = this.empresa_value = this.datosLogin.empresa_id;
        this.puesto.sucursal_id = this.sucursal_value = this.datosLogin.sucursal_id;
      }
    }
  },
  created() {
    this.resetValues();
    this.cargar();
  },
};
</script>
<style scoped>
    .displayTemplate{
        display: contents;
    }
</style>